<template>
<Page :title="title">
<article id="page-newcontent">
<Collection  :name="`law/${id}`"  v-slot="{ model }">
<p>{{setmodelinfo(model)}}</p>
  <div class="breadcrumb">
    <ol class="w-1200 r m-auto">
      <li><a href="/" class="home">首页</a></li>
      <li>
        <router-link :to="`/law_res/list/${model.law_ctype}`">{{model.law_ctype}}</router-link>
      </li>
      <li><a>详情</a></li>
    </ol>
  </div>
  <section class="container">
    <div class="row">
      <div class="content" id="printart">
        <h1 id="main_title">{{model.law_title}}</h1>
        <div class="article-infos">
          <p> <span>发布时间：{{ moment(model.create_time).format('YYYY-MM-DD') }}</span> <span>来源：{{model.law_dept}}</span></p>
          <p></p>
        </div>
        <section id="main">
          <div id="zoom" :class="'fsize'" v-html="model.law_content">
          </div>
        </section>
      </div>
    </div>
  </section>
  </Collection>
</article>
</Page>
</template>
<script>
import Collection from '@/components/Collection'
import Page from '@/components/base/Page'
export default {
  name: 'Expert',
  components: {
    Collection,
    Page
  },
  data () {
    return {
      id: this.$route.params.id,
      fsize: 'size16p',
      info: {},
      title: '案例'
    }
  },
  methods: {
    setmodelinfo (info) {
      this.info = info
      this.title = info.law_title
    },
    setFontSize (fontSize) {
      this.fsize = fontSize
    }
  },
  activated () {
    this.id = this.$route.params.id
  }
}
</script>
<style scoped src="../../assets/css/article.css"></style>
